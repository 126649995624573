.mobile-menu {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 100%;
	background: $dark-blue;
	z-index: 1001;
	transition: all .3s ease-out;

	&.open {
		left: 0;
	}

	ul {
		color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
		justify-content: space-around;
		text-align: center;
		padding: 0;
		margin: 0;
		list-style: none;
		padding: 1rem;

		li {
			flex-grow: 1;
			position: relative;
			margin: 2rem 0;
			display: flex;
    	align-items: center;

			span {
				z-index: 2;
				font-size: 1.25rem;
				text-transform: uppercase;
				font-family: 'monserrat black', sans-serif;
			}

			img {
				max-height: 100%;
				position: absolute;
				z-index: 1;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.close {
		position: absolute;
		top: 2.5rem;
		right: 2.5rem;
		cursor: pointer;

		&::before{
			content: '';
			display: block;
			position: absolute;
			width: 1.5rem;
			border: solid 2px $white;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 1.5rem;
			border: solid 2px $white;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(135deg);
		}
	}
}