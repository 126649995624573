.bg-dark-blue {
	background: $dark-blue;
}
.bg-light-green {
	background: $light-green;
}
.bg-light-blue {
	background: $light-blue;
}
.bg-aqua {
	background: $aqua;
}

.dark-blue {
	color: $dark-blue;
}
.light-green {
	color: $light-green;
}
.light-blue {
	color: $light-blue;
}
.aqua {
	color: $aqua;
}

.dark-blue-contrast {
	color: $dark-blue-contrast;
}
.light-green-contrast {
	color: $light-green-contrast;
}
.light-blue-contrast {
	color: $light-blue-contrast;
}
.aqua-contrast {
	color: $aqua-contrast;
}

