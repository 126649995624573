//colors
$dark-blue: #140e37;
$light-green: #00ad7d;
$light-blue: #058cbb;
$aqua: #56c5d0;

$dark-blue-contrast: #2322c5;
$light-green-contrast: #b3fcd0;
$light-blue-contrast: #56c5d0;
$aqua-contrast: #00ffff;

$black: #000;
$white: #fff;

//break points
$small: 576px;
$medium:768px;
$large: 992px;
$x-large: 1200px;