* {
	box-sizing: border-box;
}

html,
body {
	padding: 0;
	margin: 0;
	font-size: 16px !important;
	cursor: url('/images/down-arrow.png'), auto;
	font-family: 'barlow light', sans-serif;
	width: 100%;
	min-height: 100%;
	overflow-x: hidden;
}

p {
	font-size: 1rem;
	line-height: 1.3;
	margin: 2rem 0;
	font-family: 'barlow light', sans-serif;
}

.main-container {
	position: relative;
	min-height: 100%;
	width: 100%;
	padding: 0 1rem;
	overflow-x: hidden;
	
	.border {
		position: fixed;
		width: 1rem;
		height: 100vh;
		background: $dark-blue;
		z-index: 999;
		top: 0;

		&.left-border {
			left: 0;
		}

		&.right-border{
			right: 0;
		}
	}
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
	font-family: 'monserrat black', sans-serif;
}

h1,.h1 {
	font-size: 1.75rem;
	text-transform: uppercase;
}

h2,.h2 {
	font-size: 1.25rem;
	text-transform: uppercase;
}

p {
	font-size: 1.25rem;
}

a {
	color: inherit;
	text-decoration: none;

	&:visited {
		color: inherit;
	}
}