.header {
	width: 100%;
	position: fixed;
	border: solid 1rem $dark-blue;
	border-bottom: 0;
	top: 0;
	left: 0;
	background: #fff;
	padding: 2.5rem 2rem 1rem;
	display: flex;
	justify-content: space-between;
	color: $dark-blue;
	z-index: 1000;

	img {
		height: 3rem;
		cursor: pointer;
	}

	.header-link {

		@media screen and (max-width: $small) {
			display: none;
		}

	}
	.nav-toggle {
		display: none;
		
		.bar {
			height: .5rem;
			width: 3rem;
			background: $dark-blue;
			border-radius: 5px;

			&:first-of-type {
				margin-bottom: .5rem;
			}
		}

		@media screen and (max-width: $small) {
			display: block;
		}
	}
}