.carousel-container {
	cursor: url('/images/right-arrow.png'), auto;

	.swiper-slide {
		width: 100vw;
		max-width: 650px;
	}
	
	.carousel-item {
		text-align: center;
		white-space: normal;
		vertical-align: top;
		position: relative;
		color: $white;  
    padding: 3rem 5rem;

		img {
			max-height: 250px;
			margin: 0 auto;
		}

		p {
			margin: 3rem 0;
		}

		.triangle {
			width: 0;
			height: 0;
			border-top: solid 40px transparent;
			border-right: solid 20px transparent;
			border-bottom: solid 40px transparent;
			display: inline-block;
			position: absolute;
			left: 0;
			top: 65%;
			z-index: 90;
	
			&.dark-blue-triangle {
				border-left: solid 40px $dark-blue;
			}
		
			&.light-blue-triangle {
				border-left: solid 40px $light-blue;
			}
		
			&.light-green-triangle {
				border-left: solid 40px $light-green;
			}
		
			&.aqua-triangle {
				border-left: solid 40px $aqua;
			}
		}

		@media screen and (max-width: $medium) {
			padding: 3rem 2rem;
		}
	}
}