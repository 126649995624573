.home-section {
	.hero-container {
		padding: 136px 3rem 0;
		width: 100%;
		position: relative;
		margin-bottom: 1rem;
		overflow: hidden;
	
		video {
			max-width: 100%;
			display: block;
			margin: 0 auto;
		}
	
		h2 {
			color: $dark-blue;
		
			&.left-link {
				position: absolute;
				top: 50vh;
				left: 0;
				transform: translate(calc(-50% + 1.75rem), -50%) rotate(270deg);
				cursor: pointer;
			}
	
			&.right-link {
				position: absolute;
				top: 50vh;
				right: 0;
				transform: translate(calc(50% - 1.75rem), -50%) rotate(90deg);
				cursor: pointer;

			}
		}


		@media screen and (max-width: $small){
			margin-top: 120px;
			padding: 5rem 0;
			height: calc(100vh - 120px);

			video {
				max-height: 100%;
				position: absolute;
				max-width: unset;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}

			h2 {
				&.left-link {
					display: none;
				}
				&.right-link {
					display: none;
				}
			}
		}
		@media screen and (max-width: $medium){
			h2 {
				&.left-link {
					top: 50%;
				}
				&.right-link {
					top: 50%;
				}
			}
		}
	}
	.home-text {
		text-align: center;
		max-width: 580px;
		margin: 4rem auto;

		h1 {
			color: $light-green;
		}

		@media screen and (max-width: $small){
			padding: 0 .5rem;
		}
	}
}

.contact-section {
	text-align: center;
	padding: 6rem 0;
	position: relative;
	background: #e3e1ff url('/images/contact-bg.jpg') center 10px no-repeat;
	background-size: cover;
	background-attachment: fixed;

	.contact-sction-container {
		max-width: 700px;
		margin: 0 auto;
		z-index: 100;
		position: relative;

		.h2 {
			color: $dark-blue;
		}
	}

	}

footer {
	padding: 4rem 3rem;
	text-align: center;
	background: $dark-blue;

	img {
		height: 6rem;
		cursor: pointer;
	}
	.h2 {
		margin: 1.5rem 0;
	}
}